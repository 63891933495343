<template>
  <div id="task">
    <swiper
        ref="banner"
        class="banner swiper-container"
        :options="bannerSwiperOptions"
    >
      <swiper-slide
          class="swiper-slide"
          :style="{ backgroundImage: `url(${banner})` }"
      >
      </swiper-slide>
    </swiper>

    <div class="task-list">
      <h2>任务列表</h2>
      <div class="list">
      <div class="task rowup">
          <div class="header item_txt">
            <img
                class="icon-complete"
                src="../assets/home/icon_complete.png"
                alt=""
            />
            <div>
              <p class="case-title">理货打卡</p>
              <p class="case-sub-title">
                生效时间：2021-11-06 - 2023-04-30
              </p>
            </div>
          </div>

        <div class="header  item_txt">
          <img
              class="icon-complete"
              src="../assets/home/icon_complete.png"
              alt=""
          />
          <div>
            <p class="case-title">YY项目-重庆2204</p>
            <p class="case-sub-title">
              生效时间：2022-04-01 - 2022-04-30
            </p>
          </div>
        </div>

        <div class="header  item_txt">
          <img
              class="icon-complete"
              src="../assets/home/icon_complete.png"
              alt=""
          />
          <div>
            <p class="case-title">YY项目-蚌埠2201</p>
            <p class="case-sub-title">
              生效时间：2020年11月3日 - 2022-01-31
            </p>
          </div>
        </div>

        <div class="header  item_txt">
          <img
              class="icon-complete"
              src="../assets/home/icon_complete.png"
              alt=""
          />
          <div>
            <p class="case-title">任务四</p>
            <p class="case-sub-title">
              预计完成时间：2020年11月3日 - 2020年11月8日
            </p>
          </div>
        </div>


        <div class="header  item_txt">
          <img
              class="icon-complete"
              src="../assets/home/icon_complete.png"
              alt=""
          />
          <div>
            <p class="case-title">任务五</p>
            <p class="case-sub-title">
              预计完成时间：2020年11月3日 - 2020年11月8日
            </p>
          </div>
        </div>

        <div class="header  item_txt">
          <img
              class="icon-complete"
              src="../assets/home/icon_complete.png"
              alt=""
          />
          <div>
            <p class="case-title">任务六</p>
            <p class="case-sub-title">
              预计完成时间：2020年11月3日 - 2020年11月8日
            </p>
          </div>
        </div>



      </div>
      </div>
    </div>

    <div class="job-list">
      <h2>最新热招岗位列表</h2>

      <div class="job">
        <div class="context fade-in">
          <div class="card">
            <p style="color: #222;font-size: 18px;font-weight: 500;line-height: 22px;transition: all .2s linear;">岗位: 药业QC</p>
            <p style="color: gray;font-size: 14px">发布日期: 2022-03-30</p>
            <p>月薪: <span style="font-size: 17px;font-weight: 500;color: #fe574a;line-height: 22px;">10000-12000</span></p>
            <p style="color: #222;font-size: 17px;font-weight: 500;line-height: 22px;transition: all .2s linear;">学历: 本科</p>
            <p style="color: #222;font-size: 17px;font-weight: 500;line-height: 24px;transition: all .2s linear;">地点: <span style="font-size: 16px">上海市浦东新区张江镇牛顿路1号通用电气药业(上海)有限公司</span></p>
            <p style="color: #222;font-size: 17px;font-weight: 500;line-height: 24px;transition: all .2s linear;margin: 6px">岗位要求: </p>
            <p style="margin: 2px;font-size: 16px"> 1、负责QC实验室日常管理工作。</p>
            <p style="margin: 2px;font-size: 16px"> 2、负责制定和修订物料、半成品、成品的内控标准和检验操作操作规程及QC实验室的SOP，协助做好质量月报工作。</p>
            <p style="margin: 2px;font-size: 16px"> 3、负责按照相关质量标准和检验操作规程完成公司物料、半成品、成品、关键生产介质的检验工作，记录复核。</p>
            <p style="margin: 2px;font-size: 16px"> 4、负责物料、产品的留样与产品稳定性考察计划的实施和结果的总结分析。</p>
            <p style="margin: 2px;font-size: 16px"> 5、负责检验方法验证工作。</p>
            <p style="margin: 2px;font-size: 16px"> 6、负责实验室检验结果偏差情况的调查。</p>
            <p style="margin: 2px;font-size: 16px"> 7、负责公司计量器具检定等管理工作。</p>
          </div>
          <div class="card">
            <p style="color: #222;font-size: 18px;font-weight: 500;line-height: 22px;transition: all .2s linear;">岗位: 流水线普工</p>
            <p style="color: gray;font-size: 14px">发布日期: 2022-03-30</p>
            <p>月薪: <span style="font-size: 17px;font-weight: 500;color: #fe574a;line-height: 22px;">6000-8000</span></p>
            <p style="color: #222;font-size: 17px;font-weight: 500;line-height: 22px;transition: all .2s linear;">学历: 大专</p>
            <p style="color: #222;font-size: 17px;font-weight: 500;line-height: 24px;transition: all .2s linear;">地点: <span style="font-size: 16px">上海市松江区车墩镇申港路2450号上海东洋油墨制造有限公司</span></p>
            <p style="color: #222;font-size: 17px;font-weight: 500;line-height: 24px;transition: all .2s linear;margin: 6px">岗位要求: </p>
            <p style="margin: 2px;font-size: 16px"> 1、男，45岁之内，能吃苦耐劳，能适应翻班。</p>
            <p style="margin: 2px;font-size: 16px"> 2、有生产型企业工作经验。</p>
            <p style="margin: 2px;font-size: 16px"> 3、公司提供（工作餐）。</p>
          </div>
          <div class="card">
            <p style="color: #222;font-size: 18px;font-weight: 500;line-height: 22px;transition: all .2s linear;">岗位: 牧场挤奶工（上海奉贤）</p>
            <p style="color: gray;font-size: 14px">发布日期: 2022-03-30</p>
            <p>月薪: <span style="font-size: 17px;font-weight: 500;color: #fe574a;line-height: 22px;">5000-6000</span></p>
            <p style="color: #222;font-size: 17px;font-weight: 500;line-height: 22px;transition: all .2s linear;">学历: 大专</p>
            <p style="color: #222;font-size: 17px;font-weight: 500;line-height: 24px;transition: all .2s linear;">地点: <span style="font-size: 16px">上海市奉贤区海湾镇上海市申星奶牛场</span></p>
            <p style="color: #222;font-size: 17px;font-weight: 500;line-height: 24px;transition: all .2s linear;margin: 6px">岗位要求: </p>
            <p style="margin: 2px;font-size: 16px"> 1、遵守牧场相关规则制度，包括安全，防疫管理制度。</p>
            <p style="margin: 2px;font-size: 16px"> 2、负责挤奶和挤奶用具的清洁和保管。</p>
            <p style="margin: 2px;font-size: 16px"> 3、严格执行挤奶员的工作操作流程。</p>
            <p style="margin: 2px;font-size: 16px"> 4、关心牛只，发现病牛，异常牛要及时汇报，配合兽医做好奶牛治 疗与预防等各项工作。</p>
            <p style="margin: 2px;font-size: 16px"> 5、负责完成牧场领导交办的其他工作任务。</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import banner from "../assets/home/task_bg.jpg";
export default {
  data() {
    return {
      banner: banner
    }
  },
  created(){
    this.$emit('changeTab',1)
  }
}
</script>
<style lang="scss" scoped>
#task{
  height: 1200px;
  width: 100%;
  background: #eceff2;
  position: relative;
}

.banner {
  height: 580px;
  position: relative;
  text-align: center;
  .swiper-slide {
    background-size: cover;
  }
  ::v-deep .swiper-pagination {
    position: absolute;
    width: 100%;
    bottom: 24px;
    z-index: 1;
    text-align: center;
  }
  ::v-deep .swiper-pagination-bullet {
    cursor: pointer;
    display: inline-block;
    background: #ffffff;
    border-radius: 2px;
    width: 46px;
    height: 4px;
    margin: 0 7px;
  }
  ::v-deep .swiper-pagination-bullet-active {
    background: var(--blue);
  }
}

.task-list{
  margin-top: 30px;
}

.job-list{
  margin-top: 30px;
}

.task{
  height: 60px;
  width: 100%;
}
.job {
  background: #eceff2;
  padding-bottom: 58px;
  .context {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 48px;
    justify-content: space-around;
  }
  .card {
    background: #fff;
    width: 370px;
    text-align: left;
    padding: 20px;
  }
  .card-title {
    font-size: 22px;
    color: #333333;
    letter-spacing: 0;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 330px;
    text-align: left;
  }
  .card-sub-title {
    font-size: 14px;
    color: #999999;
    letter-spacing: 0;
    line-height: 14px;
    text-align: left;
    padding: 12px 0;
  }
  .card-desc {
    font-size: 14px;
    color: #999999;
    letter-spacing: 0;
    text-align: justify;
    line-height: 24px;
    width: 333px;
    height: 115px;
    overflow-y: scroll;
    //overflow: hidden;
  }
}
.header {
  margin-top: 20px;
  width: 400px;
  text-align: left;
  margin:  0 auto;
  .icon-complete {
    width: 41px;
    height: 41px;
    float: left;
    margin-right: 20px;
  }
  .case-title {
    font-size: 15px;
    color: #333333;
  }
  .case-sub-title {
    font-size: 12px;
    color: #999999;
    line-height: 12px;
  }
}



@-webkit-keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -107px, 0);
    transform: translate3d(0, -107px, 0);
  }
}
@keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -107px, 0);
    transform: translate3d(0, -107px, 0);
  }
}

.list{
  width: 100%;
  position: relative;
  height: 140px;
  overflow: hidden;
}

.list .rowup{
  -webkit-animation: 10s rowup linear infinite normal;
  animation: 10s rowup linear infinite normal;
  position: relative;
}
</style>